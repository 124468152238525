import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpeg'


const Hero = () => {
    return (

        <section className="wpo-hero-slider" >
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Transformação digital, a mudança que a sua empresa precisa!!!</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Capacite sua empresa com a transformação digital, melhorando processos através de nossas soluções inovadoras e personalizadas.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <Link to="/contact" className="theme-btn">Fale conosco</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2> Simplifique os seus processos de negócios!!</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Podemos automatizar os seus processos repetitivos através de rotinas personalizadas.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <Link to="/contact" className="theme-btn">Fale Conosco</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                ...
            </Swiper>
        </section>
    )
}

export default Hero;