import simg1 from '../images/services/office-365.png'
import simg2 from '../images/services/icon-2.png'
import simg3 from '../images/services/icon-3.png'
import simg4 from '../images/services/icon-4.png'

import sSimg1 from '../images/service-single/1.jpg'
import sSimg2 from '../images/service-single/1.jpg'
import sSimg3 from '../images/service-single/1.jpg'
import sSimg4 from '../images/service-single/1.jpg'

import icon1 from '../images/services/office-365.png'
import icon2 from '../images/services/sharepoint.png'
import icon3 from '../images/services/power-automate.png'
import icon4 from '../images/services/aspnet.png'
import icon5 from '../images/services/net-core.png'
import icon6 from '../images/services/net-framework.png'
import icon7 from '../images/services/integration.png'
import icon8 from '../images/services/azure.png'

const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      icon: icon1,
      title: 'Microsoft 365',
      slug: 'Fale conosco',
      description: `Consultoria em Office 365. Aproveite ao máximo o potencial destas ferramentas. Temos a solução adequada para o seu negócio.`,
   },
      {
         Id: '02',
         sImg: simg2,
         sSImg: sSimg2,
         icon: icon2,
         title: 'SharePoint',
         slug: 'Special-Child-Care',
         description: 'Soluções customizadas, criação de portais e intranets, aplicações para gestão de conteúdos e documentos (GED), portais colaborativos, fluxo de aprovações por área e muito mais.',
      },
      {
         Id: '03',
         sImg: simg3,
         sSImg: sSimg3,
         icon: icon3,
         title: 'Power Automate',
         slug: 'Mosque-Development',
         description: 'Fluxos de aprovações, automatização de processos, otimização de tempo para se dedicar apenas ao  negócio.',
      },
      {
         Id: '04',
         sImg: simg4,
         sSImg: sSimg4,
         icon: icon4,
         title: 'ASP.NET / ASP.NET MVC',
         slug: 'Charity-&-Donation',
         description: 'Soluções em aplicações Web ou especialistas com vasta experiência para manter as suas aplicações já em produção.',
      },
      {
         Id: '05',
         sImg: simg2,
         sSImg: sSimg2,
         icon: icon5,
         title: '.NET CORE',
         slug: 'Special-Child-Care',
         description: 'Soluções para migração de aplicações legadas. Estamos preparados para auxiliar a sua empresa a evoluir com esta tecnologia. ',
      },
      {
         Id: '06',
         sImg: simg2,
         sSImg: sSimg2,
         icon: icon6,
         title: '.NET Framework',
         slug: 'Special-Child-Care',
         description: 'Nossa equipe está pronta para auxilia-lo, na migração para outra tecnologia, ou manter as suas aplicações e serviços.',
      }
      ,
      {
         Id: '07',
         sImg: simg2,
         sSImg: sSimg2,
         icon: icon7,
         title: 'Integrações',
         slug: 'Special-Child-Care',
         description: 'Soluções em centralização de informações, seja em planilhas, arquivos e etc. Aumente a performace e confiabilidade dos seus dados. ',
      }
      ,
      {
         Id: '07',
         sImg: simg2,
         sSImg: sSimg2,
         icon: icon8,
         title: 'Azure',
         slug: 'Special-Child-Care',
         description: 'Escale as suas aplicações de modo a aumentar ou diminuir rápido e fácil a performance das suas soluções, migre suas soluções e serviços para a nuvem.',
      }
]

export default Services;