import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = (props) => {

    let page = "wpo-page-title";

    if (props.pagesub === "Service"){
        page = "wpo-page-title-service";
    }
    else if(props.pagesub == "Contact"){
        page = "wpo-page-title-contact";
    }

    return (
        <section className={page}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        {/* <div className="wpo-breadcumb-wrap">
                            <h2>{props.pageTitle}</h2>
                            <ol className="wpo-breadcumb-wrap">
                                <li><Link to="/home">Home</Link></li>
                                <li>{props.pagesub}</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;

