import { useEffect } from 'react'
import { Link, useHistory, useNavigate } from 'react-router-dom';

const Error = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log(window.location.href);
        console.log(window.location.pathname);
        navigate('/');
    }, []);

    return (
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row" />
            </div>
        </section>
    )
}

export default Error;