import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.css';
require("../ContactFrom/contact.css");

const ContactForm = () => {
  
    const [message, setMessage] = useState("Por favor preencha os campos abaixo!!!");
    const [classMessage, setClassMessage] = useState("alert alert-secondary");

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {

        e.preventDefault();

        console.log(forms.subject);

        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: ''
            })

            let templateParams = {
                from: forms.name,
                email: forms.email,
                subject: forms.subject,
                phone: forms.phone,
                message: forms.message,
                local: "Formulário do site"
            };

            emailjs.send('service_v10549m', 'template_psm2p1a', templateParams, "4RaVcelg564SLmSIb")
                .then(function (response) {
                    console.log(templateParams);
                    console.log(response);

                    setClassMessage("alert alert-success");
                    setMessage("Obrigado pelo seu contato. Nossa equipe irá entrar em contato com você o mais breve possível");

                }, function (error) {
                    console.log('ERRO...', error);
                });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div id="message" className={classMessage} role="alert">{message}</div>
            <div className="row">

                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Seu Nome" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Seu E-mail" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Seu telefone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            name="subject">
                            <option>Aplicações Customizadas</option>
                            <option>Consultoria Microsoft Office 365</option>
                            <option>SharePoint - Soluções Customizadas</option>
                            <option>SharePoint - Migração - On-Premisse para Online</option>
                            <option>Integração</option>
                            <option>ASP.NET</option>
                            <option>.NET Core</option>
                            <option>Construção de APIs</option>

                        </select>
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Mensagem">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>

            <div className="submit-area">
                <button type="submit" className="theme-btn">Enviar Agora</button>
            </div>
        </form >
    )
}

export default ContactForm;