import React from 'react'
import { Link } from 'react-router-dom'
import abImg from '../../images/about.jpg'
import VideoModal from '../ModalVideo/VideoModal'

require('../about/about.css');

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="wpo-about-area-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={abImg} alt="nv solucoes" title="nv solucoes"/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span>Quem Somos</span>
                                <h2>Somos uma equipe impulsionados pelo desafio de superar obstáculos.</h2>
                            </div>
                            <h5>Com mais de 15 anos de experiência, empregamos a tecnologia para enfrentar desafios que são comuns a todas as empresas.</h5>
                            <p>Desde o gerenciamento de processos à otimização de operações, nossa abordagem centrada no cliente visa oferecer soluções customizadas. Utilizamos a tecnologia não apenas para resolver problemas, mas para transformá-los em oportunidades de crescimento e inovação</p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/service" className="theme-btn">Descubra mais</Link >
                                {/* <ul>
                                    <li className="video-holder">
                                        <VideoModal />
                                    </li>
                                    <li className="video-text">
                                        Watch Our Video
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;


